import { SearchBoxData } from './interventions';
import { InterventionState } from '~/services/api.types';
import { ALL_INTERVENTIONS } from './internalLinks';

export const filters = {
  linkToInterventionsInProgress: `${ALL_INTERVENTIONS}?filter=true&status=${InterventionState.InProgress}`,
  linkToQuotesToSend: `${ALL_INTERVENTIONS}?filter=true&status=${InterventionState.InProgress}&quoteAgcStatus=${SearchBoxData.NOT_SENT}&quoteAgencyStatus=${SearchBoxData.DONE}`,
  linkToVisitReportsToSend: `${ALL_INTERVENTIONS}?filter=true&visitReportAgcStatus=${SearchBoxData.NOT_DONE}&visitReportAgencyStatus=${SearchBoxData.DONE}`,
  linkToInvoicesToSend: `${ALL_INTERVENTIONS}?filter=true&status=${InterventionState.InProgress}&invoiceAgcStatus=${SearchBoxData.NOT_DONE}&invoiceAgencyStatus=${SearchBoxData.DONE}`
};

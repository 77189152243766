import http from './httpService';
import { BUILDINGS, INTERVENTIONS } from '~/constants/api';
import useFetch from '~/hooks/useFetch';
import {
  Comment,
  PagedResultDtoOfDisplayInterventionDto,
  CreateInterventionDto,
  DisplayInterventionDto,
  CreateCommentDto,
  UpdateSavWorkStartDate,
  Sav,
  CreateSavDto,
  DisplaySingleInterventionDto,
  CreateInterventionTaskDto,
  PagedResultDtoOfDisplayInterventionTaskDto,
  UpdateCommentDto2,
  DisplayInterventionTaskDto,
  QuoteType,
  CreateComplementaryInterventionDto,
  DisplayRevenuesDto,
  DisplayTodoStatsDto,
  CreateInvoicePaymentAuthorizationDto,
  PagedResultDtoOfDisplayReminderDto,
  DisplayCommentDto,
  CreateReminderDto,
  UpdateReminderDto,
  ReminderKeys,
  CreateTemporaryCommentDto,
  CommentRelatedEntityType,
  UpdateTemporaryCommentDto,
  InvoicePaymentAuthorizationState
} from './api.types';
import {
  BuildingInterventionResponse,
  CreateMessageWithDateDto
} from '~/containers/interventions/Interventions.types';

interface InterventionsFindResponse {
  data: PagedResultDtoOfDisplayInterventionDto | null;
  isLoading: boolean;
}

export const useFetchInterventions = (
  rowsPerPage: number,
  rowToSkip: number,
  apiQuerySearchParams: string,
  hasToRefetch: boolean
): InterventionsFindResponse => {
  const { data, isLoading } = useFetch(
    `${INTERVENTIONS}?Take=${rowsPerPage}&Skip=${rowToSkip}&${apiQuerySearchParams}`,
    true,
    hasToRefetch
  );
  return { data, isLoading };
};

interface InterventionsFindByRefResponse {
  interventionsByReference: PagedResultDtoOfDisplayInterventionDto | null;
  isLoading: boolean;
  errorInterventionsByReference: string | null;
}

export const useFetchInterventionsByReference = (
  isInvoiceRelated: boolean,
  reference: string | null
): InterventionsFindByRefResponse => {
  const referenceType = isInvoiceRelated
    ? 'GcInvoiceReference'
    : 'GcQuoteReference';
  const {
    data: interventionsByReference,
    isLoading,
    error: errorInterventionsByReference
  } = useFetch(`${INTERVENTIONS}?${referenceType}=${reference}`, !!reference);
  return { interventionsByReference, isLoading, errorInterventionsByReference };
};

export async function createIntervention(
  val: CreateInterventionDto
): Promise<DisplayInterventionDto> {
  const { data } = await http.post(INTERVENTIONS, val);
  return data;
}

export async function addOrderToIntervention(
  id: number,
  val: CreateMessageWithDateDto
): Promise<number> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/order`, val);
  return data;
}

export async function addCommentToIntervention(
  id: number,
  val: CreateCommentDto
): Promise<Comment> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/comment`, val);
  return data;
}

export async function editInterventionComment(
  interventionId: number,
  commentId: number,
  val: CreateCommentDto
): Promise<Comment> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/comments/${commentId}`,
    val
  );
  return data;
}

export async function markInterventionAsCancel(
  id: number,
  terminationMotive: string
): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${id}/mark-as-cancel`, {
    terminationMotive
  });
  return data;
}

export async function markInterventionAsLost(
  id: number,
  terminationMotive: string
): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${id}/mark-as-lost`, {
    terminationMotive
  });
  return data;
}

export async function interventionDeleteFile(
  id: number,
  path: string,
  fileId: string = ''
): Promise<number> {
  const { data } = await http.delete(
    `${INTERVENTIONS}/${id}/${path}/${fileId}`
  );
  return data;
}

export async function interventionDeleteSavFile(
  key: string,
  id: number,
  savId: number,
  fileId: string
): Promise<number> {
  const path = key === 'files' ? 'file' : 'reception-record';
  const { data } = await http.delete(
    `${INTERVENTIONS}/${id}/sav/${savId}/${path}/${fileId}`
  );
  return data;
}

export async function interventionDeleteSav(
  id: number,
  savId: number
): Promise<number> {
  const { data } = await http.delete(`${INTERVENTIONS}/${id}/sav/${savId}`);
  return data;
}

export async function interventionUpdateSavWorkStartDate(
  id: number,
  savId: number,
  val: UpdateSavWorkStartDate
): Promise<Sav> {
  const { data } = await http.put(
    `${INTERVENTIONS}/${id}/sav/${savId}/work-start-date`,
    val
  );
  return data;
}

export async function interventionAddSavDate(
  id: number,
  val: CreateSavDto
): Promise<Sav> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/sav`, val);
  return data;
}

export async function uploadInterventionFile(
  endpoint: string,
  formData: FormData
): Promise<string> {
  const { data } = await http.post(endpoint, formData);
  return data;
}

export async function updateInterventionIsVisitReportRequired(
  id: number,
  val: boolean
): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${id}`, {
    isVisitReportRequired: val
  });
  return data;
}

export async function updateInterventionPreventionPlanStatus(
  id: number,
  val: boolean
): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${id}`, {
    hasPreventionPlan: val
  });
  return data;
}

export async function updateInterventionAssignedAgency(
  id: number,
  agencyId: number
): Promise<number> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${id}/assign-agency/${agencyId}`
  );
  return data;
}

interface InterventionByIdFindResponse {
  intervention: DisplaySingleInterventionDto | null;
  isLoading: boolean;
  error: string | null;
}

export const useFetchInterventionById = (
  interventionId: number,
  hasToRefetch: boolean = false
): InterventionByIdFindResponse => {
  const {
    data: intervention,
    isLoading,
    error
  } = useFetch(
    `${INTERVENTIONS}/${interventionId}`,
    !!interventionId,
    hasToRefetch
  );

  return { intervention, isLoading, error };
};

interface InterventionsOrBuildingInterventionsFindResponse {
  interventionsData: BuildingInterventionResponse | null;
  isLoadingInterventions: boolean;
  errorFetchingInterventions: string | null;
}

export const useFetchInterventionsOrBuildingInterventions = (
  hasFilter: boolean,
  querySearchParams: string,
  buildingId: number,
  interventionsFromBuildingHistory: any[] | undefined,
  hasToRefetch: boolean
): InterventionsOrBuildingInterventionsFindResponse => {
  const {
    data: interventionsData,
    isLoading: isLoadingInterventions,
    error: errorFetchingInterventions
  } = useFetch(
    hasFilter
      ? `${INTERVENTIONS}?${querySearchParams}`
      : `${BUILDINGS}/${buildingId}/interventions`,
    (!!buildingId && !interventionsFromBuildingHistory) || hasFilter,
    hasToRefetch
  );

  return {
    interventionsData,
    isLoadingInterventions,
    errorFetchingInterventions
  };
};

export async function addTodo(
  id: number,
  val: CreateInterventionTaskDto
): Promise<DisplayInterventionTaskDto> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/todo`, val);
  return data;
}

interface InterventionsTodosFindReponse {
  data: PagedResultDtoOfDisplayInterventionTaskDto | null;
  isLoading: boolean;
}

export const useFetchInterventionTodos = (
  interventionId: number,
  rowsPerPage: number,
  rowToSkip: number
): InterventionsTodosFindReponse => {
  const { data, isLoading } = useFetch(
    `${INTERVENTIONS}/${interventionId}/todo?Take=${rowsPerPage}&Skip=${rowToSkip}`,
    true
  );
  return { data, isLoading };
};

export async function updateTodoComment(
  interventionId: number,
  todoId: number,
  val: UpdateCommentDto2
): Promise<DisplayInterventionTaskDto> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/todo/${todoId}/comment`,
    val
  );
  return data;
}

export async function markTodoAsComplete(
  interventionId: number,
  todoId: number
): Promise<DisplayInterventionTaskDto> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/todo/${todoId}/mark-as-complete`
  );
  return data;
}

export async function getTodoCount(interventionId: number): Promise<number> {
  const { data } = await http.get(
    `${INTERVENTIONS}/${interventionId}/todo/count`
  );
  return data;
}

export async function restoreIntervention(
  interventionId: number
): Promise<number> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/restore`
  );
  return data;
}

export interface CreateReminderWithDateDto
  extends Omit<CreateReminderDto, 'completionDate'> {
  completionDate?: Date | null;
}

export async function createReminder(
  interventionId: number,
  val: CreateReminderWithDateDto,
  isTemporary?: boolean
): Promise<number> {
  let path = '';
  if (isTemporary) {
    path = `${INTERVENTIONS}/${interventionId}/temporary-reminder`;
  } else {
    path = `${INTERVENTIONS}/${interventionId}/reminder`;
  }
  const { data } = await http.put(path, val);
  return data;
}

export interface UpdateReminderWithDateDto
  extends Omit<UpdateReminderDto, 'completionDate'> {
  completionDate?: Date | null;
}

export async function editReminder(
  interventionId: number,
  reminderId: number,
  reminderKey: ReminderKeys,
  gcBillId: undefined | number,
  val: UpdateReminderWithDateDto
): Promise<number> {
  let path = 'reminders';
  switch (reminderKey) {
    case ReminderKeys.InterventionInvoice:
      if (gcBillId) {
        path = `gc-invoices/${gcBillId}/reminders`;
      }
      break;
    case ReminderKeys.InterventionDeposit:
      if (gcBillId) {
        path = `gc-deposits/${gcBillId}/reminders`;
      }
      break;
  }
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/${path}/${reminderId}`,
    val
  );
  return data;
}

export async function createInterventionFollowingCe(
  id: number,
  type: string,
  val: CreateComplementaryInterventionDto
) {
  let followingType = '';
  if (type === QuoteType.SupplementaryCe) {
    followingType = 'supplementary-ce';
  }
  if (type === QuoteType.MinorWorks) {
    followingType = 'minor-works';
  }

  const { data } = await http.post(
    `${INTERVENTIONS}/${id}/${followingType}`,
    val
  );
  return data;
}

export async function editIntervention(id: number, val: any): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${id}`, val);
  return data;
}

export async function getTodoStats(): Promise<DisplayTodoStatsDto> {
  const { data } = await http.get(`${INTERVENTIONS}/todo/stats`);
  return data;
}

export async function getInterventionsRevenues(): Promise<DisplayRevenuesDto> {
  const { data } = await http.get(`${INTERVENTIONS}/revenues`);
  return data;
}

export async function postAgencyPaymentAuthorization(
  isDeposit: boolean,
  interventionId: number,
  agencyInvoiceId: number,
  val: CreateInvoicePaymentAuthorizationDto,
  paymentDueDate?: Date | null
): Promise<number> {
  const path = isDeposit ? 'agency-deposits' : 'agency-invoices';
  const payload = isDeposit
    ? val
    : val.state === InvoicePaymentAuthorizationState.Unauthorized
    ? val
    : { ...val, paymentDueDate };
  const { data } = await http.post(
    `${INTERVENTIONS}/${interventionId}/${path}/${agencyInvoiceId}/payment-authorization`,
    payload
  );
  return data;
}

export async function getReminderByIntervention(
  interventionId: number
): Promise<PagedResultDtoOfDisplayReminderDto> {
  const { data } = await http.get(
    `${INTERVENTIONS}/${interventionId}/reminders`
  );
  return data;
}

export async function addGcBillComment(
  interventionId: number,
  gcBillId: number | undefined,
  val: CreateCommentDto,
  type: { isGcDeposit: boolean; isGcCreditNote: boolean; isGcQuote: boolean }
): Promise<DisplayCommentDto> {
  let path = 'gc-invoices';
  switch (true) {
    case type.isGcDeposit:
      path = 'gc-deposits';
      break;
    case type.isGcCreditNote:
      path = 'gc-credit-notes';
      break;
  }

  const endpoint = type.isGcQuote
    ? `${INTERVENTIONS}/${interventionId}/gc-quote/comment`
    : `${INTERVENTIONS}/${interventionId}/${path}/${gcBillId}/comment`;

  const { data } = await http.post(endpoint, val);
  return data;
}

export async function editGcBillComment(
  interventionId: number,
  commentId: number,
  gcBillId: number | undefined,
  val: CreateCommentDto,
  type: { isGcDeposit: boolean; isGcCreditNote: boolean; isGcQuote: boolean }
): Promise<DisplayCommentDto> {
  let path = 'gc-invoices';
  switch (true) {
    case type.isGcDeposit:
      path = 'gc-deposits';
      break;
    case type.isGcCreditNote:
      path = 'gc-credit-notes';
      break;
  }

  const endpoint = type.isGcQuote
    ? `${INTERVENTIONS}/${interventionId}/gc-quote/comments/${commentId}`
    : `${INTERVENTIONS}/${interventionId}/${path}/${gcBillId}/comments/${commentId}`;

  const { data } = await http.patch(endpoint, val);
  return data;
}

export async function cancelOrder(interventionId: number): Promise<number> {
  const { data } = await http.delete(
    `${INTERVENTIONS}/${interventionId}/order`
  );
  return data;
}

export async function unassignAgency(interventionId: number): Promise<number> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/unassign-agency`
  );
  return data;
}

export async function updatePrematurBill(
  interventionId: number,
  isPrematureInvoiceAllowed: boolean
): Promise<number> {
  const { data } = await http.patch(`${INTERVENTIONS}/${interventionId}`, {
    isPrematureInvoiceAllowed
  });
  return data;
}

export async function interventionBulkAction(val: any) {
  const { data } = await http.post(`${INTERVENTIONS}/manager-actions`, {
    ...val
  });
  return data;
}

export async function addOrderCommentToIntervention(
  id: number,
  val: CreateCommentDto
): Promise<Comment> {
  const { data } = await http.post(`${INTERVENTIONS}/${id}/order/comment`, val);
  return data;
}

export async function editOrderInterventionComment(
  interventionId: number,
  commentId: number,
  val: CreateCommentDto
): Promise<Comment> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/order/comments/${commentId}`,
    val
  );
  return data;
}

export async function addAppointmentCommentToIntervention(
  id: number,
  val: CreateCommentDto
): Promise<Comment> {
  const { data } = await http.post(
    `${INTERVENTIONS}/${id}/appointment/comment`,
    val
  );
  return data;
}

export async function editAppointmentInterventionComment(
  interventionId: number,
  commentId: number,
  val: CreateCommentDto
): Promise<Comment> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/appointment/comments/${commentId}`,
    val
  );
  return data;
}

export async function addQuoteTemporaryComment(
  interventionId: number,
  val: CreateTemporaryCommentDto
): Promise<DisplayCommentDto> {
  const newVal = { ...val, relatedEntityType: CommentRelatedEntityType.Quote };
  const { data } = await http.post(
    `${INTERVENTIONS}/${interventionId}/temporary-comment`,
    newVal
  );
  return data;
}

export async function EditQuoteTemporaryComment(
  interventionId: number,
  commentId: number,
  val: UpdateTemporaryCommentDto
): Promise<DisplayCommentDto> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/temporary-comments/${commentId}`,
    val
  );
  return data;
}

export async function addTemporaryComment(
  interventionId: number,
  val: CreateTemporaryCommentDto,
  type: { isGcDeposit: boolean; isGcCreditNote: boolean; isGcQuote: boolean }
): Promise<DisplayCommentDto> {
  const relatedEntityType = type.isGcDeposit
    ? CommentRelatedEntityType.Deposit
    : type.isGcCreditNote
    ? CommentRelatedEntityType.CreditNote
    : type.isGcQuote
    ? CommentRelatedEntityType.Quote
    : CommentRelatedEntityType.Invoice;
  const newVal = {
    ...val,
    relatedEntityType
  };
  const { data } = await http.post(
    `${INTERVENTIONS}/${interventionId}/temporary-comment`,
    newVal
  );
  return data;
}

export async function EditTemporaryComment(
  interventionId: number,
  commentId: number,
  val: UpdateTemporaryCommentDto
): Promise<DisplayCommentDto> {
  const { data } = await http.patch(
    `${INTERVENTIONS}/${interventionId}/temporary-comments/${commentId}`,
    val
  );
  return data;
}
